<template>
  <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1080.26 386.29">
    <g id="Layer_1-2" data-name="Layer 1">
      <g>
        <rect
          x="517.41"
          y="-356.77"
          width="45.03"
          height="1068.52"
          transform="translate(717.41 -362.43) rotate(90)"
          fill="#52b0ff"
        />
        <g fill="currentColor">
          <path
            d="m0,109.63l37.59-7.2c.68,19.36,6.75,28.59,19.59,28.59,11.03,0,16.21-5.18,16.21-20.04V0h39.17v101.75c0,39.85-16.43,56.28-56.28,56.28S3.15,133.95,0,109.63Z"
          />
          <path
            d="m136.92,117.15V42.42h38.27v75.41c0,10.81,5.18,15.08,13.51,15.08,11.03,0,17.78-6.75,17.78-22.51V42.42h38.27v112.56h-36.47v-30.39h-.9c-5.63,26.34-18.01,33.09-34.89,33.09-23.86,0-35.57-12.61-35.57-40.52Z"
          />
          <path
            d="m264.09,131.11l20.71-13.96c6.75,12.61,17.56,17.56,31.52,17.56,10.58,0,15.31-3.15,15.31-9,0-6.75-4.5-10.36-17.11-12.61l-15.76-2.93c-21.16-3.83-32.64-16.21-32.64-33.54,0-23.19,18.68-36.69,47.27-36.69s41.65,11.71,48.85,24.76l-20.04,12.83c-5.18-11.71-15.53-15.53-24.99-15.53s-15.08,3.6-15.08,9.45,4.28,8.78,15.08,10.81l15.98,2.93c23.64,4.28,35.12,13.96,35.12,34.89,0,19.81-12.61,37.59-49.75,37.59-27.46,0-46.15-10.58-54.48-26.57Z"
          />
          <path
            d="m391.05,124.58v-59.66h-15.31v-22.51h15.31V12.92h38.27v29.49h18.46v22.51h-18.46v56.28c0,7.43,2.93,10.36,8.55,10.36h9.91v23.41h-24.99c-24.09,0-31.74-7.65-31.74-30.39Z"
          />
          <path
            d="m459.81,42.42h41.42l15.53,70.91h.9l19.81-70.91h31.52l19.14,70.91h.9l17.11-70.91h30.17l-34.89,112.56h-32.87l-20.04-70.69h-.9l-19.14,70.69h-32.87l-35.79-112.56Z"
          />
          <path
            d="m639.88,98.69c0-36.02,23.41-58.98,58.53-58.98s58.53,22.96,58.53,58.98-23.41,58.98-58.53,58.98-58.53-22.96-58.53-58.98Zm76.99,0c0-29.04-6.3-36.69-18.46-36.69s-18.46,7.65-18.46,36.69,6.3,36.69,18.46,36.69,18.46-7.65,18.46-36.69Z"
          />
          <path
            d="m773.79,42.42h36.02v37.37h.9c5.4-30.84,15.76-38.05,32.42-38.05h2.03v33.77h-18.46c-11.03,0-14.63,3.6-14.63,16.88v62.58h-38.27V42.42Z"
          />
          <path
            d="m908.95,109.95l-11.03,12.16v32.87h-37.82V.24h37.82v90.58l39.17-48.4h33.99l-37.14,40.52,39.62,72.04h-40.52l-24.09-45.02Z"
          />
          <path
            d="m976.03,131.11l20.71-13.96c6.75,12.61,17.56,17.56,31.52,17.56,10.58,0,15.31-3.15,15.31-9,0-6.75-4.5-10.36-17.11-12.61l-15.76-2.93c-21.16-3.83-32.64-16.21-32.64-33.54,0-23.19,18.68-36.69,47.27-36.69s41.65,11.71,48.85,24.76l-20.04,12.83c-5.18-11.71-15.53-15.53-24.99-15.53s-15.08,3.6-15.08,9.45,4.28,8.78,15.08,10.81l15.98,2.93c23.64,4.28,35.12,13.96,35.12,34.89,0,19.81-12.61,37.59-49.75,37.59-27.46,0-46.15-10.58-54.48-26.57Z"
          />
        </g>
        <g fill="currentColor">
          <path
            d="m678.64,267.54h40.19c19.83,0,31.45,9.79,31.45,27.53s-11.61,27.53-31.45,27.53h-20.49v34.97h-19.7v-90.04Zm35.36,42.15c10.57,0,16.57-4.83,16.57-14.62s-6-14.62-16.57-14.62h-15.66v29.23h15.66Z"
          />
          <path
            d="m754.07,341.14c0-11.61,6.52-15.4,21.27-19.7l10.44-2.87c6.13-1.83,8.35-3.92,8.35-8.74,0-5.09-2.48-8.09-8.35-8.09-7.57,0-11.09,4.18-11.35,13.44l-18.53-1.7c1.57-15.14,11.61-22.71,29.88-22.71,19.57,0,27.53,7.7,27.53,24.79v27.27c0,2.48.91,3.52,3,3.52h2.74v11.88h-9.27c-9.13,0-13.96-2.48-14.48-11.48l-.13-2.22c-3.65,10.18-10.44,14.62-21.01,14.62-12.53,0-20.1-6.92-20.1-18.01Zm40.06-9.79v-7.7c-1.57,1.04-3.78,1.83-6.39,2.74l-3.39,1.04c-7.96,2.48-10.96,5.87-10.96,11.22s3.13,8.48,8.22,8.48c6.79,0,12.53-6,12.53-15.79Z"
          />
          <path
            d="m826.49,372.59h7.18c6.13,0,8.74-1.96,10.7-7.57l1.83-5.35-26.36-67.33h20.23l13.44,41.89,1.04,6.13h.52l14.09-48.02h17.23l-27.27,77.12c-4.7,13.31-10.44,16.83-23.75,16.83h-8.87v-13.7Z"
          />
          <path
            d="m894.87,292.34h19.18v10.18c3.78-7.83,8.48-10.96,18.14-10.96h2.22v16.18h-8.22c-8.09,0-12.14,3.92-12.14,13.31v36.54h-19.18v-65.25Z"
          />
          <path
            d="m939.11,324.96c0-20.88,13.31-34.19,33.41-34.19s33.41,13.31,33.41,34.19-13.31,34.19-33.41,34.19-33.41-13.31-33.41-34.19Zm46.85,0c0-15.79-6-21.92-13.44-21.92s-13.44,6.13-13.44,21.92,6,21.92,13.44,21.92,13.44-6.13,13.44-21.92Z"
          />
          <path d="m1019.24,263.63h19.18v93.96h-19.18v-93.96Z" />
          <path d="m1055,263.63h19.18v93.96h-19.18v-93.96Z" />
        </g>
      </g>
    </g>
  </svg>
</template>

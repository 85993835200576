<template>
  <div>
    <nav class="main-nav">
      <PageLink
        to="/dashboard"
        class="button btn-icon stacked-box home"
        :class="{ 'nav-active': isActive('dashboard') }"
        @click="current = ''"
      >
        <component :is="isActive('dashboard') ? IconHouseDuotone : IconHouse" />
        <small>{{ $t('Home') }}</small>
      </PageLink>
      <button
        v-if="showNavPayments"
        class="btn-icon stacked-box payments"
        :class="{ 'nav-active': isActive('payments') }"
        @click="toggleCurrent('payments')"
      >
        <component :is="isActive('payments') ? IconCurrencyCircleDollarDuotone : IconCurrencyCircleDollar" />
        <small>{{ $t('Payments') }}</small>
      </button>
      <button
        v-if="showNavManage"
        class="btn-icon stacked-box manage"
        :class="{ 'nav-active': isActive('manage') }"
        @click="toggleCurrent('manage')"
      >
        <component :is="isActive('manage') ? IconUsersDuotone : IconUsers" />
        <small>{{ $t('People') }}</small>
      </button>
      <button
        v-if="showNavCompany"
        class="btn-icon stacked-box company"
        :class="{ 'nav-active': isActive('company') }"
        @click="toggleCurrent('company')"
      >
        <component :is="isActive('company') ? IconBuildingsDuotone : IconBuildings" />
        <small>{{ $t('Company') }}</small>
      </button>
      <button
        class="btn-icon stacked-box account"
        :class="{ 'nav-active': isActive('account') }"
        @click="toggleCurrent('account')"
      >
        <component :is="isActive('account') ? IconUserDuotone : IconUser" />
        <small>{{ $t('You') }}</small>
      </button>
    </nav>
    <BottomSheet :open="!!current" @close="current = ''">
      <keep-alive>
        <div class="navlist">
          <div class="sheet-header">
            <PageLink to="/dashboard">
              <LogoPayroll
                width="96"
                height="48"
                style="color: var(--color-text)"
                aria-label="Justworks Payroll — Dashboard"
              />
            </PageLink>
            <div class="options">
              <LanguageSelector />
              <AppearanceSwitch />
            </div>
          </div>
          <component :is="nav" mobile />
        </div>
      </keep-alive>
    </BottomSheet>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import { AppearanceSwitch } from '@justworkshr/alma'
import LanguageSelector from '@/components/LanguageSelector.vue'
import LogoPayroll from '@/components/LogoStackedPayroll.vue'
import NavCompany from '@/components/NavCompany.vue'
import NavManage from '@/components/NavManage.vue'
import NavMember from '@/components/NavMember.vue'
import NavPayments from '@/components/NavPayments.vue'
import PageLink from '@/components/PageLink.vue'
import useNavPermissions from '@/use/useNavPermissions.js'
import IconBuildings from '~icons/ph/buildings'
import IconBuildingsDuotone from '~icons/ph/buildings-duotone'
import IconCurrencyCircleDollar from '~icons/ph/currency-circle-dollar'
import IconCurrencyCircleDollarDuotone from '~icons/ph/currency-circle-dollar-duotone'
import IconHouse from '~icons/ph/house-line'
import IconHouseDuotone from '~icons/ph/house-line-duotone'
import IconUser from '~icons/ph/user'
import IconUserDuotone from '~icons/ph/user-duotone'
import IconUsers from '~icons/ph/users'
import IconUsersDuotone from '~icons/ph/users-duotone'
import BottomSheet from './BottomSheet.vue'

const current = ref('')
const route = useRoute()

function toggleCurrent(name) {
  current.value = current.value === name ? '' : name
}

const navs = {
  payments: NavPayments,
  company: NavCompany,
  account: NavMember,
  manage: NavManage,
}

const { showNavPayments, showNavManage, showNavCompany } = useNavPermissions()

const nav = computed(() => navs[current.value])

const innerHeight = window.innerHeight

const collapse = ref(false)

function onResize() {
  collapse.value = window.innerHeight === innerHeight
}

function isActive(section) {
  return (current.value === '' && route.name.startsWith(section)) || current.value === section
}

window.addEventListener('resize', onResize)
</script>

<style scoped>
.main-nav {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(2rem, 4rem));
  justify-content: space-around;
  width: 100%;
  grid-area: main-nav;
  position: fixed;
  z-index: 2;
  bottom: 0;
  right: 0;
  left: 0;
  background: var(--color-nav-default);
  padding-top: 0.75rem;
  padding-inline: 0;
  padding-bottom: calc(var(--space-md) + env(safe-area-inset-bottom));
  transform: translateY(0);
  transition-property: background-color, transform;
  border-top: 1px solid var(--color-line);
  box-shadow: 0 -1px 8px rgb(0 0 0 / 8%);
}

.stacked-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 auto;
}

.sheet-header {
  display: flex;
  justify-content: space-between;
  padding: var(--space-md);
  height: 80px; /* 48 logo height plus md padding */
  padding-right: var(--space-lg);
}

.options {
  display: flex;
  gap: var(--space-md);
  align-items: center;
}

.navlist {
  background: var(--color-nav-sheet);
}

.navlist :deep(header) {
  font-size: var(--text-xl);
}

.nav-active > small {
  font-weight: 700;
}

.nav-active.home {
  color: var(--color-nav-blue);
  text-decoration-line: none;
}

.nav-active.payments {
  color: var(--color-nav-blue);
}

.nav-active.manage {
  color: var(--color-nav-blue);
}

.nav-active.company {
  color: var(--color-nav-blue);
}

.nav-active.account {
  color: var(--color-nav-blue);
}

.btn-icon {
  color: var(--color-text);
  gap: 0;
  font-size: 2rem;
}
</style>

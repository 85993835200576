import { ref, computed } from 'vue'
import { t } from '@/i18n.js'

const errorCode = ref(null)
const errorMessage = computed(() => {
  if (!errorCode.value) {
    return ''
  } else {
    return displayErrors.value[errorCode.value] ?? displayErrors.value[500]
  }
})

const reqId = ref(null)

const messages = computed(() => ({
  fetchError: t('Welp, our servers are down. Or maybe your internet is?'),
  clientError: t('Hmm. Something’s wrong. Maybe try again in a bit.'),
  serverError: t('Hmm. Something’s wrong. Maybe try again in a bit.'),
  unauthorized: t('Please log in to continue using Justworks Payroll.'),
  forbidden: t('Sorry! You don’t have access to this page.'),
  expired: t('Uh oh, something is missing. Please contact your manager.'),
}))

const displayErrors = computed(() => ({
  900: messages.value.fetchError,
  901: messages.value.serverError,
  401: messages.value.unauthorized,
  400: messages.value.clientError,
  403: messages.value.forbidden,
  404: messages.value.clientError,
  410: messages.value.expired,
  500: messages.value.serverError,
}))

function setGlobalError(code, requestId) {
  errorCode.value = code
  reqId.value = requestId
}

function resetGlobalError() {
  errorCode.value = null
  reqId.value = null
}

export default function useGlobalError() {
  return { errorCode, errorMessage, setGlobalError, resetGlobalError, reqId }
}

<template>
  <Row gap="xs">
    <a :title="requestId" class="button btn-outline btn-small" :href="dataDogUrl" target="_blank">View request</a>

    <Button v-if="isSupported" class="btn-outline btn-small" :title="requestId" @click="onClickCopy">
      Copy ID
      <IconCopy v-if="!copied" class="copy-icon" />
      <IconCheck v-else font-size="1rem" color="var(--color-green-400)" :title="requestId" />
    </Button>
  </Row>
</template>

<script setup>
import { computed } from 'vue'
import { Row, Button } from '@justworkshr/alma'
import { useClipboard } from '@vueuse/core'
import IconCheck from '~icons/ph/check'
import IconCopy from '~icons/ph/copy'

const props = defineProps({
  requestId: {
    type: [String, null],
    required: true,
  },
})

const { copy, isSupported, copied } = useClipboard()

const dataDogUrl = computed(
  () => `https://app.datadoghq.com/logs?query=%40http.request_id%3A${props.requestId}&live=true`
)

function onClickCopy() {
  if (props.requestId.length) copy(props.requestId)
}
</script>

import { createApp } from 'vue'
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'
import App from './App.vue'
import head from './head.js'
import i18n from './i18n.js'
import safeHtml from './plugins/safe-html.js'
import trim from './plugins/trim.js'
import router from './router.js'
import './check-version.js'
import './datadog.js'
import './validation'

const app = createApp(App)
app.use(router).use(head).use(i18n).use(trim).use(safeHtml).use(autoAnimatePlugin).mount('#app')

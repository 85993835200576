function trimText(node) {
  Array.from(node).forEach((node) => {
    if (node.nodeType === Node.TEXT_NODE && node.textContent) {
      node.textContent = node.textContent.trim()
      return
    }
    trimText(node.childNodes)
  })
}

export default {
  install: (app) => {
    app.directive('trim', {
      created(el) {
        trimText(el.childNodes)
      },
      beforeUpdate(el) {
        trimText(el.childNodes)
      },
    })
  },
}

import { useBreakpoints } from '@vueuse/core'

const breakpoints = useBreakpoints({
  tablet: '48rem',
  laptop: '64rem',
  desktop: '80rem',
})

const mobile = breakpoints.smaller('tablet')
const tablet = breakpoints.greaterOrEqual('tablet')
const desktop = breakpoints.greater('desktop')

export default function useBreakpoint() {
  return { mobile, tablet, desktop }
}

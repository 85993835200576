import { computed } from 'vue'
import { viewer, viewerCan } from '@/viewer.js'

const showNavPayments = computed(() => {
  return viewerCan('make_payments', 'pay_contractors_and_vendors', 'view_invoices_reporting', 'manage_all_timecards')
})

const showNavManage = computed(() => {
  return viewerCan(
    'manage_employees',
    'view_all_employee_information',
    'manage_all_paid_time_off',
    'manage_paid_time_off'
  )
})

const showNavCompany = computed(() => {
  return viewer.value.activeMembership.member.member_status !== 'terminated'
})

const showNavMember = computed(() => viewer.value.ghost_type === '')

export default function useNavPermissions() {
  return { showNavPayments, showNavManage, showNavCompany, showNavMember }
}

<template>API: {{ data?.version }} &bullet; Uptime: {{ secondsToDhms(uptime) }}</template>

<script setup>
import { onUnmounted, ref } from 'vue'
import useApiFetch from '@/use/useApiFetch.js'

const { data, get } = useApiFetch('/probe/live')

const uptime = ref(0)

function secondsToDhms(seconds) {
  seconds = Number(seconds)
  const d = Math.floor(seconds / (3600 * 24))
  const h = Math.floor((seconds % (3600 * 24)) / 3600)
  const m = Math.floor((seconds % 3600) / 60)
  const s = Math.floor(seconds % 60)

  const dDisplay = d > 0 ? d + (d == 1 ? ' day, ' : ' days, ') : ''
  const hDisplay = h > 0 ? h + (h == 1 ? ' hour, ' : ' hours, ') : ''
  const mDisplay = m > 0 ? m + (m == 1 ? ' minute, ' : ' minutes, ') : ''
  const sDisplay = s > 0 ? s + (s == 1 ? ' second' : ' seconds') : ''
  return dDisplay + hDisplay + mDisplay + sDisplay
}

get().then(() => {
  uptime.value = data.value.uptime_seconds
})

const interval = setInterval(() => {
  if (!uptime.value) return
  uptime.value++
}, 1000)

onUnmounted(() => {
  clearInterval(interval)
})
</script>

<template>
  <NavList :header-label="$t('Company')" class="nav-list" :aria-label="$t('Company')">
    <template #icon>
      <IconBuildings />
    </template>
    <template #navItems>
      <template v-if="canSeeBenefitsLink">
        <a v-if="sageMemberId" :href="benefitsSSOLink">
          {{ $t('Benefits') }}
        </a>
        <PageLink v-else-if="sageIntegrationEnabled" :to="`/company/${id}/benefits`">
          {{ $t('Benefits') }}
        </PageLink>
        <PageLink v-else :to="`/company/${id}/benefits`">
          {{ $t('Benefits') }}
        </PageLink>
      </template>
      <PageLink :to="`/company/${id}/directory`">{{ $t('Directory') }}</PageLink>
      <PageLink :to="`/company/calendar`">{{ $t('Calendar') }}</PageLink>
      <PageLink :to="`/company/${id}/documents`">{{ $t('Documents') }}</PageLink>
      <PageLink :to="`/company/${id}/reports`">{{ $t('Reports') }}</PageLink>
      <PageLink :to="`/company/${id}/accounting-integration`">{{ $t('Accounting integration') }}</PageLink>
      <PageLink :to="`/company/${id}/settings/company-details`">{{ $t('Settings') }}</PageLink>
    </template>
  </NavList>
</template>

<script setup>
import { computed } from 'vue'
import { viewer, viewerCan } from '@/viewer.js'
import NavList from '@/components/NavList.vue'
import PageLink from '@/components/PageLink.vue'
import IconBuildings from '~icons/ph/buildings'

const sageIntegrationHost = import.meta.env.VITE_APP_SAGE_INTEGRATION_BASE_URL

const id = computed(() => viewer.value.activeCompany.company_id)
const canSeeBenefitsLink = computed(() => viewerCan('manage_company_benefits'))
const sageMemberId = computed(() => viewer.value?.activeMembership.member.sage_member_id)
const benefitsSSOLink = computed(
  () => `${sageIntegrationHost}admin?target_member_id=${viewer.value?.activeMembership.member.benefits_id}`
)
const sageIntegrationEnabled = computed(() => viewer.value?.activeCompany.sage_integration_status !== 'disabled')
</script>

<style scoped>
.nav-list {
  --nav-active-background-color: var(--color-highlight-blue);
  --nav-active-border-color: var(--color-nav-blue);
  --nav-icon-color: var(--color-nav-blue);
}
</style>

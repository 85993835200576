import { watchEffect } from 'vue'
import { createI18n } from 'vue-i18n'

const browserLanguage = navigator.language.startsWith('es') ? 'es-US' : 'en'

const i18n = createI18n({
  legacy: false,
  locale: localStorage.locale || browserLanguage,
  fallbackLocale: 'en',
  globalInjection: true,
  fallbackFormat: true,
  fallbackRoot: true,
  missingWarn: false,
  fallbackWarn: false,
  warnHtmlMessage: false,
})

function fallbackLocale(locale) {
  return locale.split('-')[0]
}

export async function loadLocaleMessages(locale) {
  if (locale === 'en') return
  const messages = await import(`./locales/${locale}.json`)
  const messagesAlma = await import(`../node_modules/@justworkshr/alma/dist/locales/${fallbackLocale(locale)}.json`)
  const messagesTaxForms = await import(
    `../node_modules/@justworkshr/tax-forms/dist/locales/${fallbackLocale(locale)}.json`
  )
  const allMessages = { ...messages.default, ...messagesAlma.default, ...messagesTaxForms.default }

  i18n.global.setLocaleMessage(locale, allMessages)
}

watchEffect(() => {
  loadLocaleMessages(i18n.global.locale.value)
})

export const { t } = i18n.global

export default i18n

import { defineRule } from '@justworkshr/alma/validation'
import { t } from '@/i18n.js'

function birthDateValidator(value) {
  let valueDate = new Date(value)
  if (valueDate.toString() === 'Invalid Date') return false
  let now = new Date()
  let nowMinus13Years = new Date()
  let nowMinus100Years = new Date()

  nowMinus13Years.setFullYear(now.getFullYear() - 13)
  nowMinus100Years.setFullYear(now.getFullYear() - 100)

  return valueDate > nowMinus100Years && valueDate < nowMinus13Years
}

export default defineRule('birthdate', () => t('That’s not a valid birthdate'), birthDateValidator)

import { useDebounceFn } from '@vueuse/core'

// eslint-disable-next-line no-undef
let latestVersion = __APP_VERSION__

async function checkVersion() {
  if (import.meta.env.DEV) return
  const response = await fetch('/app-version.json')
  const data = await response.json()
  latestVersion = data.version
}

export function updateAvailable() {
  // eslint-disable-next-line no-undef
  return __APP_VERSION__ !== latestVersion
}

const debouncedCheckVersion = useDebounceFn(checkVersion, 500)

addEventListener('focus', debouncedCheckVersion)

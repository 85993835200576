import { createRouter, createWebHistory } from 'vue-router'
import { featureIsOn } from '@/features.js'
import { getViewer, viewer, loaded, setActiveCompany, viewerCan, isActiveMemberTypeRestricted } from '@/viewer.js'
import { setupLayouts } from 'virtual:generated-layouts'
import generatedRoutes from 'virtual:generated-pages'
import useCustomerStages from '@/use/useCustomerStages.js'
import useGlobalError from '@/use/useGlobalError.js'
import { updateAvailable } from './check-version.js'

const { resetGlobalError } = useGlobalError()
const { showEnrollment } = useCustomerStages()

const routes = [...setupLayouts(generatedRoutes), ...[{ path: '/', redirect: '/dashboard' }]]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
})

export async function beforeEach(to, from, params) {
  const { viewer, showEnrollment, loaded } = params
  const { viewerCan, isActiveMemberTypeRestricted, featureIsOn, setActiveCompany, getViewer } = params

  if (!loaded.value) {
    await getViewer()
  }

  const companyID = to.params.company_id || to.query.co
  if (companyID) setActiveCompany(companyID)

  // route auth
  const routeIsNoAuth = to.meta.noAuth
  const routeNeedsAuth = !to.meta.noAuth

  // if viewer is undefined, treat as logged out user
  const userIsLoggedInWithOkta = !!viewer.value?.okta_id
  const userHasJWPayroll = !!viewer.value?.user_id

  // redirect to login unless logged in to Okta or Ghost member
  if (routeNeedsAuth && !userHasJWPayroll && !userIsLoggedInWithOkta) {
    const params = { s: to.fullPath }
    const query = new URLSearchParams(params)
    const apiHost = import.meta.env.VITE_APP_API_HOST
    resetGlobalError()
    window.location.replace(`${apiHost}/sso/login?${query}`)
    return false
  }

  // route profile
  const routeIsDashboard = to.path === '/dashboard'
  const routeIsEnroll = to.path == '/enroll'
  const routeRequiresPermission = !!to.meta.permissions
  const routeHasMemberTypeRestrictions = !!to.meta.restrictedMemberTypes
  const feature = to.meta.feature

  // permissions and restrictions
  const viewerHasPermission = routeRequiresPermission && viewerCan(...to.meta.permissions)
  const memberTypeIsRestricted =
    routeHasMemberTypeRestrictions && isActiveMemberTypeRestricted(...to.meta.restrictedMemberTypes)

  // disallow
  if (feature && !featureIsOn(feature)) return false
  if (routeRequiresPermission && !viewerHasPermission) return false
  if (routeHasMemberTypeRestrictions && memberTypeIsRestricted) return false

  // allow
  if (routeIsNoAuth) return

  // redirect
  if (userIsLoggedInWithOkta && !userHasJWPayroll) return '/welcome'
  if (routeIsDashboard && showEnrollment.value) return '/enroll'
  if (routeIsEnroll && !showEnrollment.value) return '/dashboard'
}

router.beforeEach((to, from) =>
  beforeEach(to, from, {
    viewer,
    showEnrollment,
    loaded,
    viewerCan,
    isActiveMemberTypeRestricted,
    featureIsOn,
    setActiveCompany,
    getViewer,
  })
)

router.afterEach(() => {
  if (updateAvailable()) window.location.reload()
  resetGlobalError()
})

export default router

import dompurify from 'dompurify'

function updateComponent(el, binding) {
  el.innerHTML = dompurify.sanitize(binding.value, { ADD_ATTR: ['target'] })
}

export default {
  install: (app) => {
    app.directive('safe-html', {
      mounted: updateComponent,
      updated: updateComponent,
    })
  },
}

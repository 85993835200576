import { datadogRum } from '@datadog/browser-rum'

if (import.meta.env.BASE_URL === '//payroll.justworks.com') {
  datadogRum.init({
    applicationId: '42ca3f2a-adbb-469f-bbd1-26d1ed96e439',
    clientToken: 'pub0f2c703ccbeb7cdf7f330aa60a458570',
    site: 'datadoghq.com',
    service: 'neon-dash',
    env: import.meta.env.BASE_URL,
    // eslint-disable-next-line no-undef
    version: __APP_VERSION__,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask',
  })
}

import { unref } from 'vue'

/** @param {{ preferred_name: string; first_name: string; last_name: string }} member */
export function formatMemberName(member) {
  if (!unref(member)) return ''
  const { preferred_name, first_name, last_name } = member

  if (!preferred_name && !first_name && !last_name) {
    return ''
  }

  return `${preferred_name || first_name} ${last_name}`
}

/** @param {{ preferred_name: string; first_name: string; last_name: string; middle_name: string; suffix: string }} member */
export function formatLegalName(member) {
  if (!unref(member)) return ''
  const { first_name, last_name, middle_name, suffix } = member
  return [first_name, middle_name, last_name, suffix].filter((str) => str).join(' ')
}

import { defineRule } from '@justworkshr/alma/validation'
import { t } from '@/i18n.js'

function isDocumentValidator(value) {
  return ['image/jpeg', 'image/png', 'image/jpg', 'application/pdf'].includes(value.type)
}

export default defineRule(
  'isDocument',
  () => t('Oops! We cannot accept the selected file type at this time.'),
  isDocumentValidator
)

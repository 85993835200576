const timeTrackingHost = import.meta.env.VITE_APP_TIME_TRACKING_BASE_URL

export function getTimeTrackingURL(memberId) {
  const query = memberId ? `?employees_filter=${memberId}` : ''

  return timeTrackingHost ? `${timeTrackingHost}/dashboard/shifts${query}` : ''
}

export function getEditTimeCardsURL(companyId) {
  return timeTrackingHost && companyId
    ? `${timeTrackingHost}/connect/justworks/sso?payroll_company_uuid=${companyId}`
    : ''
}

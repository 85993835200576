import { defineRule } from '@justworkshr/alma/validation'
import { t } from '@/i18n.js'

const patterns = {
  '##-##-###': /^\d{2}-?\d{2}-?\d{3}$/,
  '##-#######': /^\d{2}-?\d{7}$/,
}

function maskValidator(value, args) {
  const pattern = patterns[args[0]]
  return pattern ? pattern.test(value) : false
}

export function messageMaskValidator(context) {
  return t('Should be formatted as {maskPattern}', { maskPattern: context.rule.params[0] })
}

export default defineRule('maskValidator', messageMaskValidator, maskValidator)
